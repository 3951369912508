@font-face {
  font-family: PlanFont;
  src: url("Tektur-Regular.2f2f3261.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: PlanFont;
  src: url("Tektur-Regular.2f2f3261.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: PlanFont;
  src: url("Tektur-Medium.9426cd10.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: PlanFont;
  src: url("Tektur-Bold.0b28259a.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: PlanFont;
  src: url("Tektur-ExtraBold.c132e2b5.ttf") format("truetype");
  font-weight: 900;
}

.form-field {
  color: #333;
  min-width: 100px;
  background-color: #fff9;
  border: none;
  border-radius: 5px;
  outline: none;
  margin: 0;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 32px;
  transition: background-color .3s;
}

.form-field-small {
  line-height: 24px;
}

.form-field:hover, .form-field:focus {
  background-color: #ffffffe6;
}

.form-field-solid {
  background-color: #fdfdfee6;
}

.form-field-solid:hover, .form-field-solid:focus {
  background-color: #f9f9fcf2;
}

.form-field-label {
  color: #00000080;
  padding: 6px 16px 2px;
  font-size: .9em;
  font-weight: 500;
  display: block;
}

.form-checkbox {
  pointer-events: none;
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.form-checkbox-label {
  cursor: pointer;
  color: #00000080;
  align-items: center;
  font-size: .9em;
  font-weight: 500;
  display: flex;
}

.form-checkbox-label-box {
  height: 25px;
  width: 25px;
  color: #0000;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff9;
  border-radius: 5px;
  justify-content: center;
  margin: 0 15px 0 0;
  font-size: 1.3em;
  transition: background-color .3s, color .3s;
  display: flex;
}

.form-checkbox-label:hover .form-checkbox-label-box, .form-checkbox:focus ~ .form-checkbox-label .form-checkbox-label-box {
  background-color: #ffffffe6;
}

.form-checkbox:checked ~ .form-checkbox-label .form-checkbox-label-box {
  color: #4d4d99;
}

.dark-theme .form-field {
  color: #ececec;
  background-color: #ffffff0d;
}

.dark-theme .form-field-solid {
  background-color: #1d1d24e6;
}

.dark-theme .form-field:hover, .dark-theme .form-field:focus {
  background-color: #ffffff1a;
}

.dark-theme .form-field-solid:hover, .dark-theme .form-field-solid:focus {
  background-color: #292930f2;
}

.dark-theme .form-field-label, .dark-theme .form-checkbox-label {
  color: #ffffff80;
}

.dark-theme .form-checkbox-label-box {
  color: #0000;
  background-color: #ffffff0d;
}

.dark-theme .form-checkbox-label:hover .form-checkbox-label-box, .dark-theme .form-checkbox:focus ~ .form-checkbox-label .form-checkbox-label-box {
  background-color: #ffffff1a;
}

.dark-theme .form-checkbox:checked ~ .form-checkbox-label .form-checkbox-label-box {
  color: #9a9acb;
}

.btn {
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 500;
  line-height: 40px;
  transition: background-color .3s;
  display: flex;
}

.btn-small {
  height: 25px;
  font-size: 14px;
  line-height: 25px;
}

.btn-round {
  height: 27px;
  width: 27px;
  border-radius: 100%;
  padding: 0;
  font-size: 14px;
}

.btn-square {
  width: 40px;
}

.btn-standard, .btn {
  color: #336;
  background-color: #c3c3db;
}

.btn-standard:focus, .btn-standard:hover, .btn:focus, .btn:hover {
  background-color: #dcdcea;
}

.btn-primary {
  color: #fff;
  background-color: #4d4d99;
}

.btn-primary:focus, .btn-primary:hover {
  background-color: #6666b2;
}

.btn-warn {
  color: #fff;
  background-color: #fa4242;
}

.btn-warn:focus, .btn-warn:hover {
  background-color: #db0606;
}

:root {
  --color-primary: #4d4d99;
}

.m-bot-5 {
  margin-bottom: 5px;
}

.m-bot-10 {
  margin-bottom: 10px;
}

.m-bot-20 {
  margin-bottom: 20px;
}

.m-top-5, .m-top-10 {
  margin-top: 10px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-right-5 {
  margin-right: 5px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-right-auto {
  margin-right: auto;
}

.m-left-auto {
  margin-left: auto;
}

.m-left-5 {
  margin-left: 5px;
}

.m-left-10 {
  margin-left: 10px;
}

.m-left-20 {
  margin-left: 20px;
}

.m-left-30 {
  margin-left: 30px;
}

.m-left-auto {
  margin-left: auto;
}

.pad-10 {
  padding: 10px;
}

.dis-relative {
  position: relative;
}

.dis-absolute {
  position: absolute;
}

.dis-flex {
  display: flex;
}

.dis-flex-column {
  flex-direction: column;
  display: flex;
}

.dis-flex-center {
  align-items: center;
  display: flex;
}

.dis-justify-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dis-column-justify-center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dis-flex-align-start {
  align-items: flex-start;
  display: flex;
}

.dis-flex-1 {
  flex: 1;
}

.dis-none {
  display: none;
}

.dis-pointer {
  cursor: pointer;
}

.dis-gap-5 {
  gap: 5px;
}

.dis-gap-10 {
  gap: 10px;
}

.t-warn {
  color: #d0d33e;
}

.t-primary {
  color: #4d4d99;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-bold {
  font-weight: 500;
}

.t-bolder {
  font-weight: 700;
}

.t-boldest {
  font-weight: 900;
}

.t-success {
  color: #246a24;
}

.t-font-12 {
  font-size: 12px;
}

.t-font-14 {
  font-size: 14px;
}

.t-font-20 {
  font-size: 20px;
}

.t-font-22 {
  font-size: 22px;
}

.t-font-27 {
  font-size: 27px;
}

.size-width-30 {
  width: 30px;
}

.size-min-height-130 {
  min-height: 130px;
}

.size-min-width-75 {
  min-width: 75px;
}

.p-10 {
  padding: 10px;
}

.container {
  cursor: default;
  max-width: 650px;
  min-width: 300px;
  height: 100%;
  background-color: #eeeef7f5;
  border-radius: 11px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  justify-self: center;
  align-items: center;
  margin: 25px auto auto;
  padding: 40px;
  display: flex;
}

.dark-theme .container {
  background-color: #111118fa;
}

.container-header {
  text-align: center;
  margin-bottom: 35px;
  font-size: 1.4em;
  font-weight: 900;
}

.container-content {
  min-width: 300px;
  max-width: 400px;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: 90px;
  display: flex;
}

.list {
  flex-direction: column;
  display: flex;
}

.list-item {
  cursor: pointer;
  background-color: #e1e1ea;
  border-radius: 11px;
  flex: 1;
  margin-bottom: 10px;
  padding: 10px 20px;
  line-height: 2;
  transition: background-color .3s;
  display: flex;
  box-shadow: 0 0 5px -3px #000;
}

.list-item:hover {
  background: #f0f0f5;
}

.list-item-content {
  flex: 1;
}

.dark-theme .list-item {
  background-color: #1d1d24;
  box-shadow: 0 0 5px -1px #000;
}

.dark-theme .list-item:hover {
  background-color: #2b2b36;
}

.icon {
  margin-left: 10px;
  font-size: 20px;
}

.icon-small {
  margin-left: 5px;
  font-size: 16px;
}

.icon-left {
  visibility: hidden;
  margin-right: 10px;
  font-size: 20px;
}

.i-20 {
  width: 20px;
}

.i-22 {
  width: 22px;
}

.i-28 {
  width: 28px;
}

.i-30 {
  width: 30px;
}

.i-35 {
  width: 35px;
}

.dark-theme .icon-invert, .icon-invert-light {
  filter: invert();
}

.dark-theme .icon-invert-light {
  filter: none;
}

.users-list-wrap {
  cursor: default;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 70px;
}

.users-list-item {
  background-color: #eeeef7f5;
  border: 1px solid #0000;
  border-radius: 11px;
  align-items: center;
  margin-bottom: 2px;
  padding: 5px 8px;
  display: flex;
}

.users-list-item-square {
  padding: 5px;
}

.dark-theme .users-list-item {
  background-color: #111118e6;
}

.users-list-menu {
  cursor: default;
  max-width: 200px;
  min-width: 200px;
  background-color: #eeeef7f5;
  border-radius: 11px;
  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.dark-theme .users-list-menu {
  background-color: #111118fa;
}

.users-list-item-edit-btn {
  cursor: pointer;
  position: absolute;
  right: -35px;
}

.cards {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card {
  width: 40px;
  height: 65px;
  cursor: pointer;
  background-color: #e1e1ea;
  border-radius: 5px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 10px;
  font-size: 17px;
  transition: background-color .3s;
  display: flex;
  box-shadow: 0 0 5px -3px #000;
}

.card:hover {
  background-color: #eaeaf0;
}

.dark-theme .card {
  background-color: #1d1d24;
  box-shadow: 0 0 5px -1px #000;
}

.dark-theme .card:hover {
  background-color: #272730;
}

.card-mini {
  width: 25px;
  height: 35px;
  padding: 5px;
  font-size: 14px;
  line-height: 35px;
}

.card.card-selected {
  font-weight: 500;
}

.dark-theme .card.card-selected, .card.card-selected {
  color: #fff;
  background-color: #4d4d99;
}

.dark-theme .card.card-selected:hover, .card.card-selected:hover {
  background-color: #55a;
}

.title {
  cursor: default;
  background-color: #eeeef7;
  border-radius: 11px;
  align-items: center;
  padding: 10px 20px 10px 10px;
  display: flex;
}

.dark-theme .title {
  background-color: #111118;
}

.title-menu-btn {
  cursor: pointer;
  background-color: #eeeef7;
  border-radius: 11px;
  margin-left: 10px;
  padding: 15px;
  display: flex;
  position: relative;
}

.dark-theme .title-menu-btn {
  background-color: #111118;
}

.title-tooltip {
  visibility: hidden;
  box-sizing: border-box;
  text-align: center;
  z-index: 2;
  z-index: 11;
  background-color: #eeeef7;
  border-color: #8f8fae4d;
  border-radius: 10px;
  align-items: center;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 0 5px -3px #000;
}

.title-menu-btn:hover .title-tooltip {
  visibility: visible;
}

.dark-theme .title-tooltip {
  background-color: #111118;
  box-shadow: 0 0 5px -1px #000;
}

.title-menu {
  z-index: 10;
  cursor: default;
  background-color: #eeeef7;
  border-radius: 11px;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 90px;
  box-shadow: 0 0 5px -3px;
}

.dark-theme .title-menu {
  background-color: #111118;
  box-shadow: 0 0 5px -1px #000;
}

.title-menu-item {
  cursor: pointer;
  max-width: 250px;
  min-width: 250px;
  height: 50px;
  background-color: #eeeef7f5;
  border-radius: 11px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
  display: flex;
  box-shadow: 0 0 5px -3px;
}

.title-menu-item:hover {
  background-color: #f8f8fc;
}

.dark-theme .title-menu-item {
  background-color: #111118fa;
  box-shadow: 0 0 5px -1px #000;
}

.dark-theme .title-menu-item:hover {
  background-color: #15151e;
}

.title-menu-item-badge {
  background-color: #eeeef7f5;
  border: 1px solid #0000;
  border-radius: 11px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 2px;
  padding: 5px 12px;
  font-size: 12px;
  display: flex;
  position: absolute;
  top: -20px;
  box-shadow: 0 0 5px -4px;
}

.dark-theme .title-menu-item-badge {
  background-color: #111118e6;
  box-shadow: 0 0 5px -1px #000;
}

.chat-container {
  cursor: default;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 70px;
  right: 5px;
}

.chat {
  max-width: 220px;
}

.chat-messages {
  max-height: 400px;
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.chat-message-container {
  display: flex;
  position: relative;
}

.chat-message {
  word-wrap: break-word;
  word-break: break-word;
  background-color: #eeeef7f5;
  border: 1px solid #0000;
  border-radius: 11px;
  margin-top: 20px;
  margin-bottom: 2px;
  padding: 5px 12px;
}

.chat-message-badge {
  background-color: #eeeef7f5;
  border: 1px solid #0000;
  border-radius: 11px;
  align-items: center;
  margin-bottom: 2px;
  padding: 5px 12px;
  font-size: 12px;
  display: flex;
  position: absolute;
}

.dark-theme .chat-message, .dark-theme .chat-message-badge {
  background-color: #111118e6;
}

.users {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.user {
  flex-direction: column;
  justify-items: center;
  margin: 20px;
  display: flex;
  position: relative;
}

.user-head {
  width: 50px;
  height: 50px;
  z-index: 2;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 25px;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.user-avatar {
  z-index: 4;
}

.user-admin {
  z-index: 3;
  font-size: 20px;
  position: absolute;
  top: -15px;
}

.user-body {
  width: 60px;
  height: 60px;
  z-index: 1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  margin-left: auto;
  margin-right: auto;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.user-nick {
  text-align: center;
}

.user-value {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

.user-tooltip {
  visibility: hidden;
  width: 63px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  z-index: 1;
  color: #fff;
  background-color: #db4b2480;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: 500;
  position: absolute;
  bottom: 21px;
}

.user-tooltip-top {
  visibility: hidden;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 3;
  box-sizing: border-box;
  text-align: center;
  background-color: #db4b2480;
  border-radius: 100%;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -15px;
  left: calc(50% - 15px);
}

.user:hover .user-tooltip, .user:hover .user-tooltip-top {
  visibility: visible;
}

.user-head, .user-body {
  background-color: #e1e1ea;
  box-shadow: 0 0 5px -3px #000;
}

.dark-theme .user-head, .dark-theme .user-body {
  background-color: #1d1d24;
  box-shadow: 0 0 5px -1px #000;
}

.user-value {
  color: #fff;
}

.user-viewer .user-head, .user-viewer .user-body {
  background-color: #e3e3f2;
  background-image: repeating-linear-gradient(45deg, #0000, #0000 15px, #c3c3db 15px 30px);
}

.user-picked .user-head, .user-picked .user-body, .dark-theme .user-picked .user-head, .dark-theme .user-picked .user-body {
  background-color: #4d4d99;
}

.user.user-picked .user-avatar {
  color: #fff;
}

.dark-theme .user-viewer .user-head, .dark-theme .user-viewer .user-body {
  background-color: #17171c;
  background-image: repeating-linear-gradient(45deg, #0000, #0000 15px, #29293d 15px 30px);
}

.stat {
  align-items: center;
  margin-bottom: 3px;
  display: flex;
}

.stat-value {
  min-width: 30px;
  width: 30px;
  height: 38px;
  border: 1px solid #0000004d;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.dark-theme .stat-value {
  border-color: #c8c8c84d;
}

.stat-chart-bcgr {
  height: 38px;
  background-image: repeating-linear-gradient(45deg, #0000, #0000 15px, #c3c3db 15px 30px);
  border-radius: 5px;
  flex: 1;
}

.dark-theme .stat-chart-bcgr {
  background-image: repeating-linear-gradient(45deg, #0000, #0000 15px, #222234 15px 30px);
}

.stat-chart {
  height: 38px;
  text-align: center;
  color: #fff;
  background-color: #4d4d99;
  border-radius: 5px;
  font-size: 14px;
  line-height: 38px;
}

.room {
  min-width: 300px;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.room-menu-btn {
  cursor: pointer;
  max-width: 300px;
  min-width: 300px;
  text-align: center;
  border-radius: 11px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  font-weight: 700;
}

.room-menu {
  cursor: default;
  max-width: 300px;
  min-width: 300px;
  background-color: #eeeef7f5;
  border-radius: 11px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.dark-theme .room-menu {
  background-color: #111118;
}

.room-average-box {
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.room-average {
  background-color: #e1e1ea;
  border-radius: 11px;
  padding: 10px 20px;
  font-size: 25px;
  font-weight: 500;
  box-shadow: 0 0 5px -3px #000;
}

.dark-theme .room-average {
  background: #1d1d24;
  box-shadow: 0 0 5px -1px #000;
}

.room-average-old {
  color: #cd3838e6;
}

.dark-theme .room-average-old {
  color: #f37a7ae6;
}

.room-average-time {
  margin-top: 5px;
  font-size: 14px;
}

.btns-area {
  height: 90px;
  min-width: 300px;
  max-width: 300px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.time {
  width: 65px;
  height: 65px;
  color: #fff;
  background-color: #4d4d99;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  display: flex;
}

.addon {
  position: relative;
}

.addon-menu {
  z-index: 999;
  width: 100%;
  background-color: #e1e1ea;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 30px;
}

.dark-theme .addon-menu {
  background: #1d1d24;
}

.addon-menu-item {
  text-align: center;
  background-color: #0000;
  border-radius: 5px;
  padding: 5px;
  transition: background-color .3s;
}

.addon-menu-item:hover {
  background-color: #eaeaf0;
}

.dark-theme .addon-menu-item:hover {
  background-color: #272730;
}

.addon-size-btn {
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 0;
  display: flex;
  box-shadow: 0 0 5px -2px #000;
}

.dark-theme .addon-size-btn {
  box-shadow: 0 0 5px 1px #000;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body, html {
  height: 100%;
}

body, input, button {
  font-family: PlanFont, Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
}

body {
  color: #333;
  background-color: #e4e4f2;
  background-image: repeating-linear-gradient(45deg, #0000, #0000 35px, #fff3 35px 70px);
}

body.dark-theme {
  color: #ccc;
  background-color: #0a0a0f;
  background-image: repeating-linear-gradient(45deg, #0000, #0000 35px, #0003 35px 70px);
}

.canvas {
  z-index: -1;
  cursor: none;
  opacity: .8;
  position: fixed;
  top: 0;
}

.game-canvas {
  outline: none;
}

.game-canvas:focus {
  box-shadow: 0 0 1px 1px #fff;
}

.dark-theme .game-canvas:focus {
  box-shadow: 0 0 1px 1px #000;
}

.site {
  padding: 5px;
  position: relative;
}

.link {
  color: #4d4d99;
  text-decoration: underline;
}

.dark-theme .link {
  color: #b4b4d9;
}

.cursor-none {
  cursor: none;
}

/*# sourceMappingURL=index.71db56d5.css.map */
