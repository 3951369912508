.users-list-wrap {
    cursor: default;
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
}

.users-list-item {
    display: flex;
    align-items: center;
    border-radius: 11px;
    padding: 5px 8px;
    border: 1px solid transparent;
    margin-bottom: 2px;
    background-color: rgb(238 238 247 / 96%);
}
.users-list-item-square {
    padding: 5px 5px;
}

.dark-theme .users-list-item {
    background-color: hsl(240deg 18% 8% / 90%);
}

.users-list-menu {
    cursor: default;
    max-width: 200px;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 11px;
    padding:20px;
    background-color: rgb(238 238 247 / 96%);
    margin-bottom: 3px;
}
.dark-theme .users-list-menu {
    background-color: hsl(240deg 18% 8% / 98%);
}

.users-list-item-edit-btn {
    cursor: pointer;
    position: absolute;
    right: -35px;
}

