.list {
    display: flex;
    flex-direction: column;
}

.list-item {
	padding: 10px 20px;
	margin-bottom: 10px;
	display: flex;
	line-height: 2;
	flex: 1;
	border-radius: 11px;
	cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #e1e1ea;
    box-shadow: 0 0 5px -3px black;
}
.list-item:hover {
    background: #f0f0f5;
}

.list-item-content {
    flex: 1;
}

.dark-theme .list-item {
    box-shadow: 0 0 5px -1px black;
    background-color: #1d1d24;
}
.dark-theme .list-item:hover {
    background-color: hsl(240, 11%, 19%);
}