:root {
    --color-primary: #4d4d99
}

.m-bot-5 {
    margin-bottom: 5px;
}
.m-bot-10 {
    margin-bottom: 10px;
}
.m-bot-20 {
    margin-bottom: 20px;
}

.m-top-5 {
    margin-top: 10px;
}
.m-top-10 {
    margin-top: 10px;
}
.m-top-20 {
    margin-top: 20px;
}
.m-top-30 {
    margin-top: 30px;
}

.m-right-5 {
    margin-right: 5px;
}
.m-right-10 {
    margin-right: 10px;
}
.m-right-auto {
    margin-right: auto;
}

.m-left-auto {
    margin-left: auto;
}
.m-left-5 {
    margin-left: 5px;
}
.m-left-10 {
    margin-left: 10px;
}
.m-left-20 {
    margin-left: 20px;
}
.m-left-30 {
    margin-left: 30px;
}
.m-left-auto {
    margin-left: auto;
}

.pad-10 {
    padding: 10px;
}

.dis-relative {
    position: relative;
}
.dis-absolute {
    position: absolute;
}

.dis-flex {
    display: flex;
}
.dis-flex-column {
    display: flex;
    flex-direction: column;
}
.dis-flex-center {
    display: flex;
    align-items: center;
}
.dis-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dis-column-justify-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dis-flex-align-start {
    display: flex;
    align-items: flex-start;
}
.dis-flex-1 {
    flex: 1;
}

.dis-none {
    display: none;
}

.dis-pointer {
    cursor: pointer;
}

.dis-gap-5 {
    gap: 5px;
}
.dis-gap-10 {
    gap: 10px;
}

.t-warn {
    color: #d0d33e;
}
.t-primary {
    color: #4d4d99;
}
.t-center {
    text-align: center;
}
.t-right {
    text-align: right;
}
.t-bold {
    font-weight: 500;
}
.t-bolder {
    font-weight: 700;
}
.t-boldest {
    font-weight: 900;
}
.t-success {
    color: #246a24;
}

.t-font-12 {
    font-size: 12px;
}
.t-font-14 {
    font-size: 14px;
}
.t-font-20 {
    font-size: 20px;
}
.t-font-20 {
    font-size: 20px;
}
.t-font-22 {
    font-size: 22px;
}
.t-font-27 {
    font-size: 27px;
}

.size-width-30 {
    width: 30px;
}

.size-min-height-130 {
    min-height: 130px;
}

.size-min-width-75 {
    min-width: 75px;
}

.p-10 {
    padding: 10px;
}

