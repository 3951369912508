@import url("./font.css");
@import url("./form.css");
@import url("./btn.css");
@import url("./utils.css");
@import url("./container.css");
@import url("./list.css");
@import url("./icon.css");
@import url("./users-list.css");
@import url("./card.css");
@import url("./title.css");
@import url("./chat.css");
@import url("./users.css");
@import url("./stats.css");
@import url("./room.css");

body {  margin: 0; overflow-x: hidden; padding: 0; }
body, html {
    height: 100%;
}
body, input, button {
    font-family: PlanFont, "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;;
}

body {
    background-color: #E4E4F2;
    color: #333;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.2) 35px, rgba(255,255,255,.2) 70px)
}

body.dark-theme {
    background-color: hsl(240deg 18% 5% / 100%);
    color: #cccccc;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(0,0,0,.2) 35px, rgba(0,0,0,.2) 70px)
}

.canvas {
    position: fixed;
    top:0; 
    z-index: -1;
    cursor: none;
    opacity: 0.8;
}

.game-canvas {
    outline: none;
}
.game-canvas:focus {
    box-shadow: 0 0 1px 1px white;
}
.dark-theme .game-canvas:focus {
    box-shadow: 0 0 1px 1px black;
}

.site {
    position: relative;
    padding:5px;
}

.link {
    text-decoration: underline;
    color:#4d4d99;
}

.dark-theme .link {
    color:#b4b4d9;
}

.cursor-none {
    cursor: none;
}