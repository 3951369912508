.chat-container {
    cursor: default;
    position: absolute;
    top: 70px;
    right:5px;
    display: flex;
    flex-direction: column;
}

.chat {
    max-width: 220px;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
}

.chat-message-container {
    position: relative;
    display: flex;
}

.chat-message {
    border-radius: 11px;
    padding: 5px 12px;
    border: 1px solid transparent;
    margin-bottom: 2px;
    margin-top:20px;
    background-color: rgb(238 238 247 / 96%);
    word-wrap: break-word;
    word-break: break-word;
}

.chat-message-badge {
    border-radius: 11px;
    padding: 5px 12px;
    border: 1px solid transparent;
    margin-bottom: 2px;
    background-color: rgb(238 238 247 / 96%);
    font-size: 12px;
    display: flex;
    align-items: center;
    position: absolute;
}

.dark-theme .chat-message,
.dark-theme .chat-message-badge {
    background-color: hsl(240deg 18% 8% / 90%);
}