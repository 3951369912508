.room {
    min-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.room-menu-btn {
    cursor: pointer;
    max-width: 300px;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 11px;
    padding:20px;
    font-weight: 700;
    text-align: center;
}

.room-menu {
    cursor: default;
    max-width: 300px;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 11px;
    padding:20px;
    background-color: rgb(238 238 247 / 96%);
}
.dark-theme .room-menu {
    background-color: hsl(240deg 18% 8% / 100%);
}

.room-average-box {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}

.room-average {
    font-weight: 500;
    font-size: 25px;
    padding:10px 20px;
    border-radius:11px;
    box-shadow: 0 0 5px -3px #000;
    background-color: #e1e1ea;
    
}
.dark-theme .room-average {
    background: #1d1d24;
    box-shadow: 0 0 5px -1px #000;
}

.room-average-old {
    color: rgb(205 56 56 / 90%);
}
.dark-theme .room-average-old {
    color: rgb(243 122 122 / 90%);
}

.room-average-time {
    margin-top: 5px;
    font-size: 14px;
}

.btns-area {
    display: flex;
    flex-direction: column;
    height: 90px;
    justify-content: center;
    min-width: 300px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.time {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 17px;
    border-radius: 100%;
    background-color: #4d4d99;
    color: white;
}

.addon {
    position: relative;
}

.addon-menu {
    z-index: 999;
    top: 30px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e1e1ea;
    border-radius: 5px;
    
}
.dark-theme .addon-menu {
    background: #1d1d24;
}

.addon-menu-item {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    background-color: transparent;
    transition: background-color 0.3s ease
}
.addon-menu-item:hover {
    background-color: #eaeaf0;
}
.dark-theme .addon-menu-item:hover {
    background-color: #272730;
}

.addon-size-btn {
    width: 18px;
    display: block;
    border-radius: 5px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0 0 5px -2px black;
}
.dark-theme .addon-size-btn {
    box-shadow: 0 0 5px 1px black;
}