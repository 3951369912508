.btn {
    height: 40px;
    line-height: 40px;
    font-size:17px;
    font-weight: 500;
    padding: 0 15px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
}
.btn-small {
    height: 25px;
    line-height: 25px;
    font-size:14px;
}
.btn-round {
    height: 27px;
    width: 27px;
    padding: 0;
    border-radius: 100%;
    font-size: 14px;
}

.btn-square {
    width: 40px;
}
.btn-standard,
.btn {
    background-color: #C3C3DB;
    color: hsl(240deg 33% 30%);
}
.btn-standard:focus,
.btn-standard:hover,
.btn:focus,
.btn:hover {
    background-color: #dcdcea;
}
.btn-primary {
    background-color: #4d4d99;
    color: white;
}
.btn-primary:focus,
.btn-primary:hover {
    background-color: #6666b2;
}
.btn-warn {
    background-color: #fa4242;
    color: white;
}
.btn-warn:focus,
.btn-warn:hover {
    background-color:#db0606;
}