.cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 65px;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    font-size: 17px;
    box-shadow: 0 0 5px -3px black;
    background-color: #e1e1ea;
    outline: none;
    transition: background-color 0.3s ease;
}
.card:hover {
    background-color: #eaeaf0;
}
.dark-theme .card {
    box-shadow: 0 0 5px -1px black;
    background-color: #1d1d24;
}
.dark-theme .card:hover {
    background-color: #272730;
}

.card-mini {
    width: 25px;
    height: 35px;
    font-size: 14px;
    padding: 5px;
    line-height: 35px;
}

.card.card-selected {
    font-weight: 500;
}

.dark-theme .card.card-selected,
.card.card-selected {
    background-color: #4d4d99;
    color: white;
}
.dark-theme .card.card-selected:hover,
.card.card-selected:hover {
    background-color: #5555aa;
}