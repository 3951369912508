.icon {
    font-size:20px;
    margin-left:10px;
}
.icon-small {
    font-size:16px;
    margin-left:5px;
}
.icon-left {
    visibility: hidden;
    font-size:20px;
    margin-right:10px;
}

.i-20 {
    width: 20px;
}
.i-22 {
    width: 22px;
}
.i-28 {
    width: 28px;
}
.i-30 {
    width: 30px;
}
.i-35 {
    width: 35px;
}

.dark-theme .icon-invert {
    filter: invert(1);
}

.icon-invert-light {
    filter: invert(1);
}
.dark-theme .icon-invert-light {
    filter: none;
}