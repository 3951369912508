.container {
    cursor: default;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    display: flex;
    max-width: 650px;
    min-width: 300px;
    margin: auto;
    justify-content: center;
    justify-self: center;
    flex: 1;
    height: 100%;
    margin-top: 25px;
    border-radius: 11px;
    background-color: rgb(238 238 247 / 96%);  
}
.dark-theme .container {
    background-color: hsl(240deg 18% 8% / 98%);
}

.container-header {
    font-weight: 900;
	font-size: 1.4em;
	text-align: center;
    margin-bottom: 35px;
}

.container-content {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 400px;
    margin-top: 45px;
    margin-bottom: 90px;
}
