@font-face {
	font-family: PlanFont;
	src:
		url('./fonts/Tektur-Regular.ttf') format("truetype");
	font-weight: 100;
}
@font-face {
	font-family: PlanFont;
	src:
		url('./fonts/Tektur-Regular.ttf') format("truetype");
	font-weight: 400;
}
@font-face {
	font-family: PlanFont;
	src:
		url('./fonts/Tektur-Medium.ttf') format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: PlanFont;
	src:
        url('./fonts/Tektur-Bold.ttf') format("truetype");
	font-weight: 700;
}
@font-face {
	font-family: PlanFont;
	src:
        url('./fonts/Tektur-ExtraBold.ttf') format("truetype");
	font-weight: 900;
}