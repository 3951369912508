.form-field {
    font-size: 16px;
    line-height: 32px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    outline: none;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.6);
    color: #333;
    transition: background-color 0.3s ease;
    min-width: 100px;
}
.form-field-small {
    line-height: 24px;
}
.form-field:hover,
.form-field:focus {
    background-color: rgba(255, 255, 255, 0.9);
}

.form-field-solid {
    background-color: rgba(253, 253, 254, 0.9);
}
.form-field-solid:hover,
.form-field-solid:focus {
    background-color: rgba(249, 249, 252, 0.95);
}


.form-field-label {
    display: block;
    padding: 6px 16px;
    padding-bottom: 2px;
    font-size: 0.9em;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5)
}

/* checkbox */
.form-checkbox {
    position: absolute;
    pointer-events: none;
    opacity: 0 ;
    z-index: -1;
}

.form-checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5)
}
.form-checkbox-label-box {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin: 0 15px 0 0;
    font-size: 1.3em;
    background-color: rgba(255, 255, 255, 0.6);
    transition: background-color 0.3s ease, color 0.3s ease;
    color: transparent;
    user-select: none;
}
.form-checkbox-label:hover .form-checkbox-label-box,
.form-checkbox:focus ~ .form-checkbox-label .form-checkbox-label-box {
    background-color: rgba(255, 255, 255, 0.9);
}
.form-checkbox:checked ~ .form-checkbox-label .form-checkbox-label-box {
    color: #4d4d99;
}


/* dark theme */
.dark-theme .form-field {
    background-color: rgba(255, 255, 255, 0.05);
    color: #ececec;
}
.dark-theme .form-field-solid {
    background-color: rgba(29, 29, 36, 0.9);
}
.dark-theme .form-field:hover,
.dark-theme .form-field:focus {
    background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .form-field-solid:hover,
.dark-theme .form-field-solid:focus {
    background-color: rgba(41, 41, 48, 0.95);
}
.dark-theme .form-field-label {
    color: rgba(255, 255, 255, 0.5)
}
/* checkbox */
.dark-theme .form-checkbox-label {
    color: rgba(255, 255, 255, 0.5)
}
.dark-theme .form-checkbox-label-box {
    background-color: rgba(255, 255, 255, 0.05);
    color: transparent;
}
.dark-theme .form-checkbox-label:hover .form-checkbox-label-box,
.dark-theme .form-checkbox:focus ~ .form-checkbox-label .form-checkbox-label-box {
    background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .form-checkbox:checked ~ .form-checkbox-label .form-checkbox-label-box {
    color: #9a9acb;
}