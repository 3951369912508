.stat {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.stat-value {
    margin-right: 10px;
    min-width: 30px;
    width: 30px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);
}
.dark-theme .stat-value {
    border-color: rgba(200, 200, 200, 0.3);
}

.stat-chart-bcgr {
    height: 38px;
    border-radius: 5px;
    flex: 1;
    background-image: repeating-linear-gradient(45deg,#0000,#0000 15px,#c3c3db 15px 30px);
}
.dark-theme .stat-chart-bcgr {
    background-image: repeating-linear-gradient(45deg,#0000,#0000 15px,#222234 15px 30px);
}

.stat-chart {
    height: 38px;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    background-color: #4d4d99;
    color: white;
}