.title {
    cursor: default;
    display: flex;
    align-items: center;
    border-radius: 11px;
    padding: 10px;
    padding-right: 20px;
    background-color: rgb(238 238 247 / 100%);
}
.dark-theme .title {
    background-color: hsl(240deg 18% 8% / 100%);
}

.title-menu-btn {
    position: relative;
    display: inline-block;
    border-radius: 11px;
    padding: 15px;
    cursor:pointer;
    margin-left:10px;
    display: flex;
    background-color: rgb(238 238 247 / 100%);
}
.dark-theme .title-menu-btn {
    background-color: hsl(240deg 18% 8% / 100%);
}
.title-tooltip {
    visibility: hidden;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(238 238 247 / 100%);
    border-color: #8f8fae4d;
    z-index: 11;
    box-shadow: 0 0 5px -3 black;
}
.title-menu-btn:hover .title-tooltip {
    visibility: visible;
}

.dark-theme .title-tooltip {
    background-color: hsl(240deg 18% 8% / 100%);
    box-shadow: 0 0 5px -1 black;
}

.title-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90;
    z-index: 10;
    font-size: 14px;
    border-radius: 11px;
    padding: 20px;
    cursor: default;
    background-color: rgb(238 238 247 / 100%);
    box-shadow: 0 0 5px -3px;
}
.dark-theme .title-menu {
    box-shadow:0 0 5px -1px #000;
    background-color: hsl(240deg 18% 8% / 100%);
}

.title-menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 250px;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 11px;
    padding: 0 30px;
    height: 50px;
    background-color: rgb(238 238 247 / 96%);
    box-shadow: 0 0 5px -3px;
    
}
.title-menu-item:hover {
    background-color: #f8f8fc;
}
.dark-theme .title-menu-item {
    background-color: hsl(240deg 18% 8% / 98%);
    box-shadow:0 0 5px -1px #000;
}
.dark-theme .title-menu-item:hover {
    background-color: #15151e;
}

.title-menu-item-badge {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    top: -20px;
    box-shadow: 0 0 5px -4px;
    border-radius: 11px;
    padding: 5px 12px;
    border: 1px solid transparent;
    margin-bottom: 2px;
    margin-top: 20px;
    background-color: rgb(238 238 247 / 96%);
}
.dark-theme .title-menu-item-badge {
    box-shadow:0 0 5px -1px #000;
    background-color: hsl(240deg 18% 8% / 90%);
}