.users {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.user {
    position: relative;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.user-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin: auto;
    font-size: 25px;
    z-index: 2;
    transition: background-color 0.3s ease;
}
.user-avatar {
    z-index: 4;
}
.user-admin {
    font-size: 20px;
    z-index: 3;
    top: -15px;
    position: absolute;
}
.user-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    margin-top: -25px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    transition: background-color 0.3s ease;
}
.user-nick {
    text-align: center;
}

.user-value {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
}
.user-tooltip {
    visibility: hidden;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: 500;
    position: absolute;
    width: 63px;
    bottom: 21px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    z-index: 1;
    color: #fff;
    background-color: #db4b2480;
}
.user-tooltip-top {
    visibility: hidden;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    font-weight: 500;
    top: -15px;
    left: calc(50% - 15px);
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    z-index: 3;
    box-sizing: border-box;
    text-align: center;
    background-color: #db4b2480;
}
.user:hover .user-tooltip{
    visibility: visible;
}
.user:hover .user-tooltip-top{
    visibility: visible;
}

.user-head,
.user-body {
    box-shadow: 0 0 5px -3px black;
    background-color: #e1e1ea;
}
.dark-theme .user-head,
.dark-theme .user-body {
    box-shadow: 0 0 5px -1px black;
    background-color: #1d1d24;
}

.user-value {
    color: white;
}
.user-viewer .user-head,
.user-viewer .user-body {
    background-color: hsl(240deg 35% 92%);
    background-image: repeating-linear-gradient(45deg,#0000,#0000 15px,#c3c3db 15px 30px);
}

.user-picked .user-head,
.user-picked .user-body,
.dark-theme .user-picked .user-head,
.dark-theme .user-picked .user-body {
    background-color: #4d4d99;
}

.user.user-picked .user-avatar {
    color: white;
}

.dark-theme .user-viewer .user-head,
.dark-theme .user-viewer .user-body {
    background-color: #17171c;
    background-image: repeating-linear-gradient(45deg,#0000,#0000 15px,#29293d 15px 30px);
}